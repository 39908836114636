import { render, staticRenderFns } from "./studentSituation.vue?vue&type=template&id=eb724372&scoped=true&"
import script from "./studentSituation.vue?vue&type=script&lang=js&"
export * from "./studentSituation.vue?vue&type=script&lang=js&"
import style0 from "./studentSituation.vue?vue&type=style&index=0&id=eb724372&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb724372",
  null
  
)

export default component.exports
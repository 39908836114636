<template>
  <div>
    <a-modal
      title="新建线索"
      :visible="visible && !showToCrm && !showFlag"
      width="680px"
      :maskClosable="false"
      dialogClass="clue-related"
      @cancel="onCancel(0)"
    >
      <div v-if="formFlag">
        <div style="margin-bottom: 15px" v-if="type === 'INSIDE_NORMAL'">
          <span>是否一家多娃：</span>
          <a-radio-group name="radioGroup" v-model="defaultChild" @change="changeChild">
            <a-radio :value="false"> 否 </a-radio>
            <a-radio :value="true"> 是 </a-radio>
          </a-radio-group>
        </div>
        <div class="tab-nav tab-child" v-show="showChildFlag">
          <div>
            <a-icon type="check-circle" v-show="showFinishFlag" style="padding-right: 5px; color: #43d186" /><span
              :class="showManyChild ? '' : 'greenColor'"
              >新建大娃</span
            >
          </div>
          <div><a-icon type="right" style="color: #999" /></div>
          <div>
            <a-icon v-show="finishStepFlag" type="check-circle" style="padding-right: 5px; color: #43d186" /><span
              :class="showChildFlag && showFinishFlag ? 'greenColor' : ''"
              >一家多娃</span
            >
          </div>
        </div>
        <div class="desc-info" v-show="showChildFlag">{{ childMsg }}</div>
        <a-form :form="form" v-show="!showManyChild" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="进线类型">
            <a-select
              disabled
              v-decorator="['incomingType', { rules: [{ required: true, message: '请选择进线类型!' }] }]"
              placeholder="请选择进线类型"
            >
              <a-select-option v-for="(item, index) in incomTypeArray" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="线索来源" v-if="type === 'INSIDE_COPY' || type === 'INSIDE_MULTI'">
            <a-input
              disabled
              v-decorator="['clueSource', { rules: [{ required: true, message: '请输入线索来源!' }] }]"
              placeholder="线索来源"
            />
          </a-form-item>
          <a-form-item label="线索来源" v-if="type === 'INSIDE_NORMAL'">
            <a-select
              v-decorator="['clueSource', { rules: [{ required: true, message: '请选择线索来源!' }] }]"
              placeholder="请选择线索来源"
              @select="selClueSource"
              @change="changeClueType"
              disabled
            >
              <a-select-option v-for="(item, index) in clueSourceArray" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="分销大使" v-if="type === 'INSIDE_NORMAL' && reseller">
            <a-alert
              slot="extra"
              type="warning"
              message=" 该学员家长为大使，如家长希望获得大使福利，请引导家长使用大使专属海报邀请好友。本渠道创建线索，家长无法获得大使福利。"
            />
            <a-input
              disabled
              v-decorator="['resellerName', { rules: [{ required: true, message: '请输入分销大使!' }] }]"
              placeholder="分销大使"
            />
          </a-form-item>
          <a-form-item label="推荐人" v-if="type === 'INSIDE_NORMAL' && !reseller">
            <a-input
              disabled
              v-decorator="['recommendStuName', { rules: [{ required: true, message: '请输入推荐人!' }] }]"
              placeholder="推荐人"
            />
          </a-form-item>
          <a-form-item label="科目">
            <a-select
              v-decorator="['subject', { rules: [{ required: true, message: '请选择线科目!' }] }]"
              placeholder="请选择科目"
              @change="subjectChagne"
              :options="subjectArray"
            />
          </a-form-item>
          <a-form-item label="姓名">
            <a-input
              :disabled="type === 'INSIDE_COPY'"
              v-decorator="['name', { rules: [{ required: true, message: '请输入线索姓名!' }] }]"
              placeholder="请输入线索姓名"
              :maxLength="64"
            />
          </a-form-item>
          <a-form-item label="手机" v-if="type === 'INSIDE_NORMAL'">
            <a-input
              v-decorator="[
                'phoneNumber',
                {
                  rules: [{ required: true, message: '请输入手机号!' }],
                },
              ]"
              style="width: 100%"
              :maxLength="64"
            >
              <a-select
                slot="addonBefore"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="['areaCode']"
                style="width: 80px"
              >
                <a-select-option :value="i" v-for="i of filterAreaCodeArr(form.getFieldValue('subject'))" :key="i">
                  {{ i }}
                </a-select-option>
              </a-select>
            </a-input>
          </a-form-item>
          <a-form-item label="常驻国家/地区">
            <a-select
              show-search
              allowClear
              :disabled="type === 'INSIDE_COPY'"
              placeholder="常驻国家/地区"
              option-filter-prop="children"
              :filter-option="filterOption"
              @change="handleCountryChange"
              v-decorator="['country', { rules: [{ required: true, message: '请选择常驻国家/地区!' }] }]"
              :options="countryOptions"
            />
          </a-form-item>
          <a-form-item label="所在时区">
            <a-select
              show-search
              placeholder="所在时区"
              :disabled="type === 'INSIDE_COPY'"
              option-filter-prop="children"
              :filter-option="filterOption"
              v-decorator="['timezoneStd', { rules: [{ required: true, message: '请选择所在时区!' }] }]"
            >
              <a-select-option :value="i.value" v-for="(i, index) of timezoneOptions" :key="index">
                {{ i.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="州/省">
            <a-select
              show-search
              allowClear
              :disabled="type === 'INSIDE_COPY'"
              placeholder="请选择"
              option-filter-prop="children"
              :filter-option="filterOption"
              @change="handleProvinceChange"
              v-decorator="['state']"
            >
              <a-select-option :value="i.value" v-for="(i, index) of provinceOptions" :key="index">
                {{ i.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="城市">
            <a-select
              show-search
              allowClear
              :disabled="type === 'INSIDE_COPY'"
              placeholder="请选择"
              option-filter-prop="children"
              :filter-option="cityFilterOption"
              @search="handleCitySearch"
              @change="handleCityChange"
              v-decorator="['city']"
              :loading="cityLoading"
            >
              <a-select-option :value="i.key" v-for="(i, index) of cityOptions" :item="i">
                {{ i.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="家长沟通语言">
            <a-select
              placeholder="请选择"
              v-decorator="['language', { rules: [{ required: true, message: '请选择家长沟通语言!' }] }]"
            >
              <a-select-option :value="i.value" v-for="(i, index) of languageArr" :key="index">
                {{ i.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <!-- 改动态下拉 -->
          <!-- <a-form-item label="当前年龄" v-if="type !== 'INSIDE_COPY'">
            <a-input-number v-decorator="['currentAge', { rules: [{ required: true, message: '请输入当前年龄!' }] }]"
              placeholder="请输入数字" style="width: 100%" />
          </a-form-item> -->
          <a-form-item label="当前年龄" v-if="type !== 'INSIDE_COPY'">
            <div slot="extra">
              <AgeExtraTip :subject="form.getFieldValue('subject')" :age="form.getFieldValue('currentAge')" />
            </div>
            <a-select
              v-decorator="['currentAge', { rules: [{ required: true, message: '请选择当前年龄!' }] }]"
              placeholder="请选择"
              :options="ageOptions"
              style="width: 100%"
            />
          </a-form-item>
          <div class="tab-nav">
            <span style="font-size: 14px; font-weight: bold">补充资料</span
            ><span style="color: #999; padding-left: 12px; font-size: 12px" v-show="!showFlagInfo"
              >性别、邮箱、家长沟通语言、微信老师、备注</span
            >
            <a-icon
              type="down"
              style="font-size: 16px; color: #999; float: right"
              @click="toggleFLag"
              v-show="!showFlagInfo"
            />
            <a-icon
              type="up"
              style="font-size: 16px; color: #999; float: right"
              @click="toggleFLag"
              v-show="showFlagInfo"
            />
          </div>
          <div v-show="showFlagInfo">
            <a-form-item label="性别" v-if="type !== 'INSIDE_COPY'">
              <a-radio-group v-decorator="['sex']" :disabled="type === 'INSIDE_COPY'">
                <a-radio value="未知"> 未知 </a-radio>
                <a-radio value="男"> 男 </a-radio>
                <a-radio value="女"> 女 </a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="邮箱" v-if="type === 'INSIDE_NORMAL'">
              <a-input
                v-decorator="['email', { rules: [{ type: 'email', message: '请输入邮箱!' }] }]"
                placeholder="邮箱"
                :maxLength="200"
                
              />
            </a-form-item>

            <a-form-item label="微信老师">
              <a-select
                show-search
                allowClear
                @search="searchWXTeacher"
                v-decorator="['wxTeacher']"
                placeholder="请选择老师"
              >
                <a-select-option v-for="item in teacherList" :key="item.uuid" :value="`${item.fullName}(${item.uuid})`"
                  >{{ item.fullName }}({{ item.phone }})</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="备注">
              <a-textarea placeholder="请输入" v-decorator="['remarks']" :rows="3" maxLength="200" />
            </a-form-item>
          </div>
        </a-form>
        <a-form :form="form1" v-show="showManyChild" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
          <div v-for="(item, index) in childFormArr" :key="index">
            <div class="child-title" v-show="showManyChild">
              <span>{{ item.name }}</span>
            </div>
            <a-form-item label="姓名">
              <a-input
                :disabled="type === 'INSIDE_COPY'"
                v-decorator="[`name${index}`, { rules: [{ required: true, message: '请输入线索姓名!' }] }]"
                placeholder="请输入线索姓名"
                :maxLength="64"
              />
            </a-form-item>
            <a-form-item label="科目">
              <a-select
                size="large"
                v-decorator="[`subject${index}`, { rules: [{ required: true, message: '请选择线科目!' }] }]"
                placeholder="请选择科目"
                @change="(e) => multiChildSubjectChange(index, e)"
              >
                <a-select-option
                  v-for="(item, index) in subjectArray"
                  :key="index"
                  :value="item.value"
                  :disabled="subjectFlag === item.value && type === 'INSIDE_COPY'"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="常驻国家/地区">
              <a-select
                show-search
                :disabled="type === 'INSIDE_COPY'"
                placeholder="常驻国家/地区"
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="[`country${index}`, { rules: [{ required: true, message: '请选择常驻国家/地区!' }] }]"
                :options="countryOptions"
                @change="(e) => multiChildCountryChange(index, e)"
              />
            </a-form-item>

            <a-form-item label="时区">
              <a-select
                show-search
                :disabled="type === 'INSIDE_COPY'"
                placeholder="请选择"
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="[`timezoneStd${index}`, { rules: [{ required: true, message: '请选择所在时区!' }] }]"
                :options="timezones"
              />
            </a-form-item>
            <!-- 改为联动下拉 -->
            <!-- <a-form-item label="当前年龄" v-if="type !== 'INSIDE_COPY'">
              <a-input-number
                style="width: 100%"
                v-decorator="[`currentAge${index}`, { rules: [{ required: true, message: '请填写当前年龄!' }] }]"
                placeholder="请输入数字"
              />
            </a-form-item> -->
            <a-form-item label="当前年龄" v-if="type !== 'INSIDE_COPY'">
              <div slot="extra">
                <AgeExtraTip
                  :subject="form1.getFieldValue(`subject${index}`)"
                  :age="form1.getFieldValue(`currentAge${index}`)"
                />
              </div>
              <a-select
                style="width: 100%"
                v-decorator="[`currentAge${index}`, { rules: [{ required: true, message: '请选择当前年龄!' }] }]"
                placeholder="请选择"
                :options="ageOptions"
              />
            </a-form-item>
            <div class="tab-nav">
              <span style="font-size: 14px; font-weight: bold">补充资料</span
              ><span v-show="!showFlagInfo" style="color: #999; padding-left: 12px; font-size: 12px">性别、备注</span>
              <a-icon
                type="down"
                style="font-size: 16px; color: #999; float: right"
                @click="toggleFLag"
                v-show="!showFlagInfo"
              />
              <a-icon
                type="up"
                style="font-size: 16px; color: #999; float: right"
                @click="toggleFLag"
                v-show="showFlagInfo"
              />
            </div>
            <div v-show="showFlagInfo" style="border-bottom: 1px solid #e8e8e8">
              <a-form-item label="性别" v-if="type !== 'INSIDE_COPY'">
                <a-radio-group v-decorator="[`sex${index}`]" :disabled="type === 'INSIDE_COPY'">
                  <a-radio value="未知"> 未知 </a-radio>
                  <a-radio value="男"> 男 </a-radio>
                  <a-radio value="女"> 女 </a-radio>
                </a-radio-group>
              </a-form-item>

              <a-form-item label="备注">
                <a-textarea placeholder="请输入" :rows="3" v-decorator="[`remarks${index}`]" maxLength="200" />
              </a-form-item>
            </div>
          </div>
        </a-form>
        <!-- 多娃场景继续添加 -->
        <div style="padding-bottom: 20px" v-show="showManyChild">
          <a-icon type="plus" /><span class="add-child" @click="addChildFun">继续添加</span>
        </div>
      </div>
      <div class="btn-wrap" slot="footer">
        <a-button @click="preStepFun" v-show="showChildFlag && showFinishFlag" style="float: left"> 上一步 </a-button>

        <a-button @click="onCancel(0)"> 取消 </a-button>
        <a-button
          type="primary"
          @click="handleSubmit"
          :disabled="submitLoading"
          v-show="!showChildFlag && !showFinishFlag"
          class="feedBackbtn"
        >
          提交
        </a-button>
        <a-button type="primary" @click="nextStepFun" v-show="showChildFlag && !showFinishFlag" class="feedBackbtn">
          下一步
        </a-button>
        <a-button type="primary" @click="finishStepFun" v-show="showFinishFlag" class="feedBackbtn"> 完成 </a-button>
      </div>
    </a-modal>

    <a-modal title="操作提示" :visible="showToCrm" :maskClosable="false" @cancel="handleCancel" :footer="null">
      <div style="padding: 0 20px">
        <p style="font-weight: 600">
          线索创建成功，并将该线索分配给你，可前去
          <span style="color: #04cb94; cursor: pointer" @click="toCrm">跟进线索</span>;
        </p>
        <p>可点击【导航栏】底部的跟进线索前去跟进</p>
      </div>
    </a-modal>

    <a-modal
      title="操作提示"
      :visible="expandResData && expandResData.canGetTrack"
      :maskClosable="false"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button @click="handleCancel">忽略（不领取）</a-button>
        <a-button type="primary" @click="onReceive">立即领取</a-button>
      </template>
      <div style="padding: 0 20px">
        <p style="font-weight: 600">该学员科目线索已存在！</p>
        <p>可点击立即领取后，跟进该线索；也可忽略</p>
      </div>
    </a-modal>

    <a-modal title="操作提示" :visible="cluesPresent" :maskClosable="false" @cancel="handleCancel">
      <template slot="footer">
        <a-button @click="handleCancel">我知道了</a-button>
      </template>
      <div style="padding: 0 20px" v-if="expandResData">
        <p :style="{ fontWeight: 600, color: expandResData.repeat ? 'red' : '#04cb94' }">
          {{ expandResData.repeat ? '该学员科目线索已存在！' : '学员拓课线索创建成功' }}
        </p>
        <p>
          该线索owner为 <span style="color: #04cb94; font-weight: 600">{{ expandResData.ownerName }}</span
          >({{ expandResData.email }}),可
          <span style="color: #04cb94; cursor: pointer" @click="copyImageHandle(expandResData.ownerQwCode)"
            >点击复制</span
          >企微二维码图片
        </p>
        <p v-if="!expandResData.repeat && expandResData.inCCTime">(该学员成交未到30天，该线索分配学习规划师)</p>
        <p v-if="!expandResData.inCCTime && isOwner && !expandResData.repeat">(拓科线索分配学员班主任)</p>
      </div>
    </a-modal>

    <a-modal title="操作提示" :visible="notTime" :maskClosable="false" @cancel="handleCancel">
      <template slot="footer">
        <a-button @click="handleCancel">我知道了</a-button>
      </template>
      <div style="padding: 0 20px" v-if="expandResData">
        <p style="font-weight: 600; color: red">该学员科目线索已存在！</p>
        <p>该学员成交未到30天，将不会分配给该学员班主任</p>
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment';
import storage from 'store';
import { UUID, USERINFOSERVER, ROLES } from '@/store/mutation-types';
import {
  countries,
  timezones,
  getStudentInfo,
  wxAccountTeacher,
  leadSourceSearch,
  normalSource,
  expandSubjectSource,
  DollSource,
  searchOwnerMyClue,
  claimPermission,
  getAgeOptions,
} from '@/api/headTeacher';

import AgeExtraTip from './AgeExtraTip.vue';
import { getTimezoneOptions, getProvinceOptions, getCityOptions, getCountryOptions } from '@/utils/geoLocation';

import { getCRMAddress } from '@/utils/util';
import debounce from 'lodash.debounce';
import { isUnknownOption } from '@/utils/geoLocation/utils/isUnknownOption';

export default {
  components: { AgeExtraTip },
  props: {
    studentProfile: {
      type: Object,
      default: () => {},
    },
    reseller: {
      type: Object,
    },
    recordObj: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
    },
    formOrigin: {
      // 表明从哪里创建的
      type: String,
      default: 'new',
    },
  },
  data() {
    return {
      cityLoading: false,
      countryOptions: [],
      provinceOptions: [],
      timezoneOptions: [],
      cityOptions: [],
      getCRMAddress,
      stuCode: '',
      finishStepFlag: false,
      bigChild: {}, // 大娃的表单数据
      manyChildObj: {}, // 多个娃
      childName: '二娃',
      defaultChild: false,
      childIndex: 1,
      childFormArr: [],
      btnMsg: '下一步',
      showFinishFlag: false,
      childMsg: '请先完成大娃信息的填写',
      showManyChild: false,
      showChildFlag: false,
      showFlagInfo: true,

      oldSubject: '',
      formFlag: false, // 异步拿到数据，回显在表单
      subjectFlag: '',
      userInfo: {},
      trackSource: {},
      studentName: '',
      teacherList: [],
      studentList: [],
      timezones: null,
      countries: null,
      areaCodeArr: [],
      languageArr: [
        { label: '中文', value: '中文' },
        { label: '中文(粤语)', value: '中文(粤语)' },
        { label: '英语', value: '英语' },
        { label: '日语', value: '日语' },
        { label: '韩语', value: '韩语' },
        { label: '法语', value: '法语' },
        { label: '德语', value: '德语' },
        { label: '印尼语', value: '印尼语' },
        { label: '其他', value: '其他' },
      ],
      incomTypeArray: [
        { label: '学员拓科', value: 'INSIDE_COPY' },
        { label: '一家多娃', value: 'INSIDE_MULTI' },
        { label: '普通线索', value: 'INSIDE_NORMAL' },
      ],
      clueSourceArray: [
        { label: '班主任自建单', value: '班主任自建单' },
        // { label: '中文班主任自建单', value: '中文班主任自建单' },
        // { label: 'Math辅导老师自建单', value: 'Math辅导老师自建单' },
        // { label: '社群转介绍活动', value: '社群转介绍活动' },
        // { label: '分销渠道', value: '分销渠道' },
      ],
      subjectArray: [
        { label: '中文', value: 'Chinese' },
        { label: '数学', value: 'Math' },
        { label: '英文', value: 'English' },
      ],
      uuid: storage.get(UUID),
      teacherName: storage.get(USERINFOSERVER).fullName,
      roles: storage.get(ROLES),
      visible: true,
      form: this.$form.createForm(this),
      form1: this.$form.createForm(this, { name: `form${this.childIndex}` }),
      formData: null,
      assignees: null,
      isAssignees: false,
      processName: '',
      options: null,
      noAssigness: false,
      classArray: [],
      pageNum: 1,
      changeCourseId: '',
      // studentArray: this.classInfoData.students || [],
      studentArray: [],
      showToCrm: false,
      leadId: '',
      num: 1,
      expandResData: null,
      cluesPresent: false,
      notTime: false,
      showFlag: false,
      isOwner: false,
      submitLoading: false,
      // 单个学生年龄下拉
      ageOptions: Array.from({ length: 16 }, (_, i) => ({
        label: i + 3,
        value: i + 3,
      })),
      // 多娃的年龄下拉
      multiChildAgeOptions: {},
    };
  },
  computed: {},
  mounted() {
    getCountryOptions().then((data) => (this.countryOptions = data));
    const obj = storage.get('userInfo');
    this.userInfo = obj;
    if (this.type === 'INSIDE_NORMAL') {
      this.formFlag = true;
      const form = this.$form.createForm(this, { name: `form${this.childIndex}` });
      this.childFormArr.push({ formName: form, name: this.childName });
      this.form.getFieldDecorator('clueSource', { initialValue: '班主任自建单' });
      this.selClueSource('班主任自建单');
    } else {
      this.formFlag = false;
    }

    // 一家多娃和学科拓展需要回填一些线索
    let subject = '';
    if (this.formOrigin === 'table') {
      this.stuCode = this.recordObj.stuNumber;
      if (this.recordObj.subject === '数学') {
        subject = 'Math';
        this.subjectFlag = 'Math';
      } else if (this.recordObj.subject === '中文') {
        subject = 'Chinese';
        this.subjectFlag = 'Chinese';
      } else if (this.recordObj.subject === '英文') {
        subject = 'English';
        this.subjectFlag = 'English';
      }
      // this.fetchAgeOptions({ country: this.recordObj.residentCountry, subject }).then((res) => (this.ageOptions = res));
    } else {
      this.stuCode = this.studentProfile.code;
      // 从新建里面来的，判断线索来源，科目,studentSubject代表表格里面某个学生的线索
      if (this.userInfo.subject === 'MATH') {
        //  this.form.getFieldDecorator('clueSource', { initialValue: 'Math辅导老师自建单' });
        if (this.type === 'INSIDE_NORMAL') {
          this.formFlag = true;
          this.form.getFieldDecorator('subject', { initialValue: 'Math' });
        }
        this.subjectFlag = 'Math';
        //   this.selClueSource('Math辅导老师自建单');
        subject = 'Math';
      } else if (this.userInfo.subject === 'CHINESE') {
        //  this.form.getFieldDecorator('clueSource', { initialValue: '中文班主任自建单' });
        if (this.type === 'INSIDE_NORMAL') {
          this.form.getFieldDecorator('subject', { initialValue: 'Chinese' });
        }
        this.subjectFlag = 'Chinese';
        //   this.selClueSource('中文班主任自建单');
        subject = 'Chinese';
      } else if (this.userInfo.subject === 'ENGLISH') {
        if (this.type === 'INSIDE_NORMAL') {
          this.form.getFieldDecorator('subject', { initialValue: 'Chinese' });
        }
        this.subjectFlag = 'English';

        subject = 'English';
      }
    }
    this.form.getFieldDecorator('incomingType', { initialValue: this.type });
    this.form.getFieldDecorator('sex', { initialValue: '未知' });
    if (this.type === 'INSIDE_COPY' || this.type === 'INSIDE_MULTI') {
      this.searchOwnerMyClue(subject);

      if (this.formOrigin === 'new') {
        this.oldSubject = null;
      }
      if (this.formOrigin === 'table') {
        this.oldSubject = subject;
      }
    }
    countries().then((i) => {
      this.countries = i.data.content;
      this.areaCodeArr = this.unique(i.data.content);
    });
    timezones().then((i) => {
      this.timezones = i.data.content;
    });
    if (this.reseller) {
      this.form.getFieldDecorator('resellerName', {
        initialValue: `${this.reseller.fullName}(${this.reseller.email})`,
      });
    } else {
      this.form.getFieldDecorator('recommendStuName', {
        initialValue: `${this.studentProfile.fullName}(${this.studentProfile.code})`,
      });
    }
  },
  methods: {
    moment,
    debounce,
    // 单个学生科目change
    filterAreaCodeArr(subject) {
      return this.areaCodeArr.filter((item) => {
        if (subject === 'Chinese') return item !== '+86';
        return true;
      });
    },
    subjectChagne(subject) {
      this.form.setFieldsValue({ currentAge: undefined });
      if (subject === 'Chinese' && this.form.getFieldValue('areaCode') === '+86') {
        this.form.setFieldsValue({ areaCode: undefined });
      }
    },
    // 多娃科目change
    multiChildSubjectChange(index, subject) {
      const country = this.form1.getFieldValue(`country${index}`);
      this.form1.setFieldsValue({ [`currentAge${index}`]: undefined });
      // this.fetchAgeOptions({ country, subject }).then((res) => (this.multiChildAgeOptions[index] = res));
    },
    // 多娃国家change
    multiChildCountryChange(index, value) {
      const subject = this.form1.getFieldValue(`subject${index}`);
      this.form1.setFieldsValue({ [`currentAge${index}`]: undefined });

      // this.fetchAgeOptions({ country: value, subject }).then((res) => (this.multiChildAgeOptions[index] = res));
    },
    // 获取年龄下拉
    async fetchAgeOptions({ subject, country } = {}) {
      if (!subject || !country) return;
      const res = await getAgeOptions({ subject: subject.toUpperCase(), country });
      return res?.data?.content
        ?.sort((a, b) => a?.year - b?.year)
        ?.map((item) => ({
          label: item.year,
          value: item.year,
        }));
    },

    async handleCountryChange(v) {
      getTimezoneOptions(v).then((res) => (this.timezoneOptions = res));
      getProvinceOptions(v).then((res) => (this.provinceOptions = res));
      this.form.setFieldsValue({
        nationalTimeZone: undefined,
        state: undefined,
        city: undefined,
      });
      // 获取年龄下拉
      // this.form.setFieldsValue({ currentAge: undefined });
      // const subject = this.form.getFieldValue('subject');
      // this.fetchAgeOptions({ country: v, subject }).then((res) => (this.ageOptions = res));
    },
    async handleProvinceChange(v) {
      getCityOptions({ countryId: this.form.getFieldValue('country'), provinceId: v, showDoubleLanguage: true }).then(
        (res) => (this.cityOptions = res),
      );
      this.form.setFieldsValue({
        city: undefined,
      });
    },
    handleCitySearch: debounce(function (v) {
      this.cityOptions = [];
      this.cityLoading = true;
      getCityOptions({
        countryId: this.form.getFieldValue('country'),
        provinceId: this.form.getFieldValue('state'),
        city: v,
        showDoubleLanguage: true,
      })
        .then((res) => {
          this.cityOptions = res;
          this.cityLoading = false;
        })
        .catch(() => {
          this.cityLoading = false;
        });
    }, 500),
    handleCityChange(v, o) {
      const country = this.form.getFieldValue('country');
      const state = this.form.getFieldValue('state');
      // 如果没有选中国家或者省份，则通过城市反填数据
      if (!country || !state) {
        const option = o?.data?.attrs?.item;
        if (isUnknownOption(option)) return;
        this.handleCountryChange(option.countryId);
        // 这里先给表单赋值，因为 handlProvinceChange 是从表单中取的国家
        // 最好通过参数传递
        this.form.setFieldsValue({
          country: option.countryId,
        });
        this.form.setFieldsValue({
          timezoneStd: option.timezone,
        });
        this.handleProvinceChange(option.provinceId);
        this.form.setFieldsValue({
          state: option.provinceId,
        });
      }
    },
    onReceive() {
      claimPermission(this.expandResData.trackId).then(() => {
        this.$message.success('领取成功');
        this.expandResData = null;
        this.onCancel('submit');
      });
    },
    copyImageHandle(url) {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = async () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        // eslint-disable-next-line no-shadow
        const blob = await new Promise((resolve) => canvas.toBlob((blob) => resolve(blob)));
        // eslint-disable-next-line no-undef
        const data = [new ClipboardItem({ [blob.type]: blob })];
        await navigator.clipboard.write(data);
        this.$message.success('复制成功');
        // .then(function () {
        //   this.$message.success('图片已复制到剪贴板');
        // })
        // .catch(function (error) {
        //   console.error('复制图片失败: ', error);
        // });
      };

      img.src = url;
    },
    preStepFun() {
      this.showManyChild = false;
      this.showFinishFlag = false;
    },
    // 完成多娃
    finishStepFun() {
      this.showFinishFlag = true;
      this.form1.validateFields((err, values) => {
        if (!err) {
          this.finishStepFlag = true;
          const formData = values;
          this.manyChildObj = formData;
          this.initSubmit(this.bigChild);
        } else {
          this.finishStepFlag = false;
        }
      });
    },
    // 添加多个孩子
    addChildFun() {
      this.finishStepFlag = false;
      this.form1.validateFields((err, values) => {
        if (!err) {
          this.finishStepFlag = true;
          const formData = values;
          this.manyChildObj = formData;
          // 添加之前，判断上个表单的必填项填写完了，才继续添加下一个
          // eslint-disable-next-line no-plusplus
          this.childIndex++;
          if (this.childIndex === 1) {
            this.childName = '二娃';
          } else if (this.childIndex === 2) {
            this.childName = '三娃';
          } else if (this.childIndex === 3) {
            this.childName = '四娃';
          } else if (this.childIndex === 4) {
            this.childName = '五娃';
          } else {
            this.childName = '多娃';
          }
          this.childFormArr.push({ name: this.childName });
          this.form1.getFieldDecorator(`subject${this.childIndex - 1}`, { initialValue: this.bigChild.subject });
          this.form1.getFieldDecorator(`timezoneStd${this.childIndex - 1}`, {
            initialValue: this.bigChild.timezoneStd,
          });
          this.form1.getFieldDecorator(`country${this.childIndex - 1}`, { initialValue: this.bigChild.country });
          // 当前年龄下拉增加一组
          this.multiChildAgeOptions[this.childIndex - 1] = this.multiChildAgeOptions[this.childIndex - 2];
        } else {
          this.finishStepFlag = false;
        }
      });
    },
    nextStepFun() {
      // 判断大娃是否填写完毕
      this.form.validateFields((err, values) => {
        if (!err) {
          // 校验大娃的信息都填写完，才展示二娃
          this.showManyChild = true;
          this.childMsg = '请继续完成多娃信息的填写';
          const formData = values;
          this.showFinishFlag = true;
          this.bigChild = formData;
          // 初始多娃的常驻国家/地区、科目、时区跟大娃的相同
          this.form1.getFieldDecorator('subject0', { initialValue: this.bigChild.subject });
          this.form1.getFieldDecorator('timezoneStd0', { initialValue: this.bigChild.timezoneStd });
          this.form1.getFieldDecorator('country0', { initialValue: this.bigChild.country });
          this.multiChildAgeOptions[0] = this.ageOptions;
        }
      });
    },

    changeChild(obj) {
      this.showChildFlag = obj.target.value;
      if (!this.showChildFlag) {
        this.showManyChild = false;
        this.childMsg = '请先完成大娃信息的填写';
        this.btnMsg = '下一步';
      }
      // if(this.showChildFlag){//创建多娃
      //   this.childMsg="请继续完成多娃信息的填写"
      // }else{
      //   this.childMsg="请先完成大娃信息的填写"
      // }
    },
    toggleFLag() {
      this.showFlagInfo = !this.showFlagInfo;
    },
    onChange() {},
    searchOwnerMyClue(subject) {
      const params = {
        stuNumber: this.stuCode,
        subject,
        type: this.type,
      };
      searchOwnerMyClue(params).then((res) => {
        const data = res.data.content;

        if (data !== null) {
          if (this.type === 'INSIDE_COPY') {
            this.form.getFieldDecorator('sex', { initialValue: data.sex !== null ? data.sex : '未知' });
            this.form.getFieldDecorator('name', { initialValue: data.name });
            this.form.getFieldDecorator('currentAge', { initialValue: data.currentAge });
            this.form.getFieldDecorator('remarks', { initialValue: data.remarks });
            this.form.getFieldDecorator('subject', { initialValue: data.subject === 'Chinese' ? 'Math' : 'Chinese' });
          } else {
            this.form.getFieldDecorator('subject', { initialValue: data.subject });
          }

          this.form.getFieldDecorator('country', { initialValue: data.residentCountry });
          this.form.getFieldDecorator('state', { initialValue: data.residentState });
          this.form.getFieldDecorator('city', { initialValue: data.residentCity });
          this.form.getFieldDecorator('timezoneStd', { initialValue: data.timezone });
          this.form.getFieldDecorator('wxTeacher', { initialValue: data.wxTeacherName });
          this.form.getFieldDecorator('language', { initialValue: data.communicationLanguage });

          this.form.getFieldDecorator('clueSource', { initialValue: data.mainSource });
          this.trackSource = {
            mainSource: data.mainSource,
            mainSourceTag: data.mainSourceTag,
          };
        }

        this.formFlag = true;
      });
    },
    // 提交普通线索
    submitNormalSource(data) {
      let num1 = 0;
      let wxTeacherName = '';
      let wxTeacherId = '';
      if (data.wxTeacher) {
        num1 = data.wxTeacher.lastIndexOf('(');
        wxTeacherName = data.wxTeacher.substring(0, num1);
        wxTeacherId = data.wxTeacher.substring(num1 + 1, data.wxTeacher.length - 1);
      }
      const params = {
        subject: data.subject,
        name: data.name,
        sex: data.sex,
        countryCode: data.areaCode,
        mobile: data.phoneNumber,
        email: data.email,
        residentCountry: data.country,
        residentCity: data.city,
        residentState: data.state,
        timezone: data.timezoneStd,
        trackSource: this.trackSource,
        communicationLanguage: data.language,
        wxTeacherName,
        wxTeacherId,
        currentAge: data.currentAge,
        remarks: data.remarks,
      };
      if (this.reseller) {
        params.resellerCode = this.studentProfile.code;
        params.resellerName = this.reseller.fullName;
        params.resellerId = this.reseller.uuid;
        params.resellerEmail = this.reseller.email;
      } else {
        params.recommendStuName = this.studentProfile.fullName;
        params.recommendCode = this.studentProfile.code;
      }

      const obj = {
        multiChild: this.defaultChild,
        trackCreates: [params],
      };
      // 判断是否创建了多娃
      if (this.defaultChild) {
        this.childFormArr.forEach((item, index) => {
          const childObj = {
            subject: this.manyChildObj[`subject${index}`],
            name: this.manyChildObj[`name${index}`],
            sex: this.manyChildObj[`sex${index}`],
            residentCountry: this.manyChildObj[`country${index}`],
            timezone: this.manyChildObj[`timezone${index}`],
            currentAge: this.manyChildObj[`currentAge${index}`],
            remarks: this.manyChildObj[`remarks${index}`],
          };
          obj.trackCreates.push(childObj);
        });
      }
      normalSource(obj).then(() => {
        this.$message.success('创建线索成功');
        this.onCancel('submit');
      });
    },
    // 学员拓科
    submitExpandSource(data) {
      let num1 = 0;
      let wxTeacherName = '';
      let wxTeacherId = '';
      if (data.wxTeacher) {
        num1 = data.wxTeacher.lastIndexOf('(');
        wxTeacherName = data.wxTeacher.substring(0, num1);
        wxTeacherId = data.wxTeacher.substring(num1 + 1, data.wxTeacher.length - 1);
      }
      this.submitLoading = true;
      const params = {
        subject: data.subject,
        name: data.name,
        sex: data.sex,
        oldSubject: this.oldSubject,
        residentCountry: data.country,
        timezone: data.timezoneStd,
        stuNumber: this.stuCode,
        trackSource: this.trackSource,
        communicationLanguage: data.language,
        wxTeacherName,
        wxTeacherId,
        currentAge: data.currentAge,
        remarks: data.remarks,
      };
      expandSubjectSource(params)
        .then((res) => {
          this.$message.success('创建线索成功');
          this.submitLoading = false;
          const userID = storage.get('userInfo')?.userID;

          this.expandResData = res.data;
          const d = res.data;
          // repeat true 线索重复
          this.cluesPresent = !!(d.ownerName && d.ownerId && d.ownerQwCode);
          this.notTime = d.ownerId === '00000000-0000-0000-0000-000000000000' && !d.canGetTrack;
          this.isOwner = userID !== res.data.ownerId;
          if (userID === res.data.ownerId && !d.inCCTime) {
            this.showToCrm = true;
            this.cluesPresent = false;
            this.leadId = res.data.trackId;
          } else if (this.cluesPresent || this.notTime || this.expandResData.canGetTrack) {
            this.showFlag = true;
          } else {
            this.onCancel('submit');
          }
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },
    toCrm() {
      getCRMAddress(`/lead/${this.leadId}`);
    },
    // 一家多娃
    submitDollSource(data) {
      let num1 = 0;
      let wxTeacherName = '';
      let wxTeacherId = '';
      if (data.wxTeacher) {
        num1 = data.wxTeacher.lastIndexOf('(');
        wxTeacherName = data.wxTeacher.substring(0, num1);
        wxTeacherId = data.wxTeacher.substring(num1 + 1, data.wxTeacher.length - 1);
      }
      const params = {
        subject: data.subject,
        name: data.name,
        sex: data.sex,
        oldSubject: this.oldSubject,
        residentCountry: data.country,
        timezone: data.timezoneStd,
        stuNumber: this.stuCode,
        trackSource: this.trackSource,
        communicationLanguage: data.language,
        wxTeacherName,
        wxTeacherId,
        currentAge: data.currentAge,
        remarks: data.remarks,
      };
      DollSource(params).then(() => {
        this.$message.success('创建线索成功');
        this.onCancel('submit');
      });
    },
    selClueSource(val) {
      this.trackSource = {};
      const params = {
        nodeName: val,
      };
      leadSourceSearch(params).then((res) => {
        this.trackSource = {
          mainSource: res.data.content.nodeName,
          mainSourceTag: res.data.content.nodeTag,
        };
      });
    },
    changeClueType(val) {
      this.$nextTick(() => {
        if (val === '分销渠道') {
          this.form.setFieldsValue({ resellerName: `${this.studentList[0].fullName}(${this.studentList[0].code})` });
        } else {
          this.form.setFieldsValue({
            recommendStuName: `${this.studentList[0].fullName}(${this.studentList[0].code})`,
          });
        }
      });
    },
    searchWXTeacher(name) {
      const params = {
        userRole: 'CRM_ICC',
        name,
      };
      wxAccountTeacher(params).then((res) => {
        this.teacherList = res.data.content;
      });
    },

    searchStudentName(name) {
      this.getStudentInfo(name);
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    cityFilterOption(value, o) {
      const input = value ? value?.trim() : value;
      if (!input) return true;
      return o?.data?.attrs?.item?.label.toLowerCase().includes(input.toLowerCase());
    },
    unique(arr) {
      const arr1 = []; // 新建一个数组来存放arr中的值
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = arr.length; i < len; i++) {
        if (arr1.indexOf(arr[i].areaCode) === -1) {
          arr1.push(arr[i].areaCode);
        }
      }
      return arr1;
    },
    getStudentInfo(name) {
      const params = {
        name,
      };
      getStudentInfo(params).then((res) => {
        if (res.status === 200) {
          this.studentList = res.data?.content?.list;
        }
      });
    },
    initSubmit(data, manyData) {
      // 普通线索
      if (this.type === 'INSIDE_NORMAL') {
        this.submitNormalSource(data, manyData);
        // 学员拓科
      } else if (this.type === 'INSIDE_COPY') {
        this.submitExpandSource(data);
      } else if (this.type === 'INSIDE_MULTI') {
        this.submitDollSource(data);
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.city) {
            const cityKeys = values.city?.split('-') ?? [];
            values.city = cityKeys?.[cityKeys?.length - 1];
          }

          const formData = values;
          this.initSubmit(formData);
        }
      });
    },
    handleCancel() {
      this.onCancel('submit');
      this.leadId = '';
    },
    onCancel(value) {
      this.$emit('cancel', value);
      this.showToCrm = false;
      this.leadId = '';
      // let that=this;
      // if(value===0){
      //   this.$confirmAnt({
      //     title: "提示",
      //     content: `取消新建将不保存本次已填写内容`,
      //     okText: '取消新建',
      //     cancelText: '继续填写',
      //     class:'cancel-clue',
      //     onOk(){
      //        that.$emit('cancel', value);

      //     },
      //     onCancel(){
      //     }
      //   });
      // }else{
      //    that.$emit('cancel', value);
      // }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-form-item {
  margin: 0px 0px 12px;
}

.clue-related {
  .associatedClass {
    padding: 10px 0 0 10px;
    border-radius: 8px;
    border: 1px solid #eee;
    margin-top: 10px;

    .associatedClassInfo {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;

      .class-name {
        display: flex;
        justify-content: flex-start;
        width: 92%;
      }

      p {
        max-width: 80%;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        margin-bottom: 0;
      }

      .title,
      .classInfoExpand {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
      }

      .studnets {
        display: flex;
        flex-wrap: wrap;

        span {
          margin-right: 5px;
        }
      }
    }

    .involved-lesson {
      flex-wrap: wrap;
      width: 100%;

      p {
        max-width: 100%;
        font-size: 14px;
        margin: 6px 0 0;
      }
    }
  }

  .ant-modal-content {
  }

  .ant-modal-header {
    border-radius: 30px 30px 0 0;
  }

  .feedBackbtn {
    margin-left: 8px;
  }

  .feedBackbtn[disabled] {
    background-color: #f5f5f5;
  }
}

.submit-tips {
  .ant-modal-content {
    padding: 20px 20px 0;

    .btn {
      display: flex;
      justify-content: space-between;

      .ant-btn {
        width: 200px;
        height: 46px;
        margin: 20px 0 40px;
        border-radius: 10px;
        font-size: 16px;
      }

      .ant-btn-primary {
        background: #009cff;
      }
    }
  }

  .tips-main {
    text-align: center;

    .tips-title {
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
      margin-bottom: 20px;
    }

    .tips-text {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      margin-bottom: 10px;
      text-align: left;

      span {
        font-weight: 600;
      }
    }

    .tips-err {
      font-size: 18px;
      font-weight: 400;
      color: #ff5353;
      line-height: 25px;
      text-align: left;
    }
  }
}

.tab-nav {
  background: #f7f7f7;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.tab-child {
  display: flex;

  div {
    flex: 1;
    text-align: center;
    font-weight: bold;
  }
}

.btn-wrap {
  text-align: right;
}

.desc-info {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

.child-title {
  font-size: 14px;
  font-weight: 600;
}

.child-title:before {
  content: '';
  display: block;
  float: left;
  margin: 4px 6px 0 0;
  width: 3px;
  height: 14px;
  background-color: #41cc83;
  border-radius: 0px 2px 2px 0px;
}

.add-child {
  cursor: pointer;
  padding-left: 2px;
  font-size: 14px;
  color: #43d186;
}

.greenColor {
  color: #43d186;
}
</style>

<template>
  <div>
    <div class="recordBtn top-recordBtn" v-show="roleType === 'CLASS_ADMIN'" @click="openRecordsModal">
      <a-icon type="plus" />跟进记录
    </div>

    <a-tabs type="card" style="margin-top: 20px" @change="changeRole">
      <a-tab-pane key="CLASS_ADMIN" tab="班主任">
        <div class="sel-nav">
          <div>
            <a-radio-group v-model="showSubject" @change="changeSubject">
              <a-radio-button v-for="item in subjectArr" :key="item.value" :value="item.value">{{
                item.label
              }}</a-radio-button>
            </a-radio-group>
          </div>
          <div style="margin-left: 10px">
            <a-select placeholder="跟进类型" allowClear style="width: 220px" @change="handleChangeType">
              <a-select-option :value="item.type" :key="index" v-for="(item, index) in followTypeArr">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <a-spin :spinning="spinning">
          <div class="timeline-wrap">
            <a-timeline v-if="recordData.length > 0">
              <a-timeline-item v-for="(item, index) in recordData" :key="index">
                <div class="timeline-date">
                  {{ item.key }}
                </div>
                <div class="timeline-content">
                  <div class="timeline-item" v-for="(record, index1) in item.value" :key="index1">
                    <div v-if="record.recordData !== null && !record.recordData.zoomRecord">
                      <div class="timeline-infoWrap">
                        <div class="avator-pic">
                          <img :src="record.headImageUrl" />
                        </div>
                        <div class="timeline-name">{{ record.operatorName }}</div>
                        <div class="reply-time">{{ record.whenHour }}</div>
                      </div>

                      <div class="timeline-txt" v-if="record.recordData.taskType === 'QA'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">质检类型：{{ record.recordData.qaType }}</p>
                          <p class="label-txt">质检来源：{{ record.recordData.qaOrigin }}</p>
                          <p class="label-txt">质检判定：{{ record.recordData.qaDetermine }}</p>
                          <p class="label-txt">奖惩分数：{{ record.recordData.rewardScore }}</p>
                          <p class="label-txt">奖惩金额：{{ record.recordData.rewardMoney }}</p>
                          <p class="label-txt">质检判定时间：{{ record.recordData.qaJudgeTime }}</p>
                          <p class="label-txt">责任人：{{ record.recordData.personLiable }}</p>
                          <p class="label-txt">质检人员：{{ record.recordData.qaPerson }}</p>
                          <p class="label-txt">质检描述：{{ record.recordData.qualityDescription }}</p>
                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【质检学科】：{{ record.recordData.qualityAccount }}</p>
                          <p class="label-txt">【质检类型】：{{ record.recordData.qualityType }}</p>
                          <p class="label-txt">【质检来源】：{{ record.recordData.qualitySource }}</p>
                          <p class="label-txt">【质检判定】：{{ record.recordData.qualityDetermine }}</p>
                          <p class="label-txt">【奖惩分数】：{{ record.recordData.rewardScore }}</p>
                          <p class="label-txt">【奖惩金额】：{{ record.recordData.rewardMoney }}</p>
                          <p class="label-txt">【质检判定时间】：{{ record.recordData.qaJudgeTime }}</p>
                          <p class="label-txt">【责任人】：{{ record.recordData.personLiable }}</p>
                          <p class="label-txt">【质检人员】：{{ record.recordData.qaPerson }}</p>
                          <p class="label-txt">【质检描述】：{{ record.recordData.qualityDescription }}</p>
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'PERIOD_CHANGE'">
                        <p class="label-txt">【转移类型】：{{ record.recordData.transferType }}</p>
                        <p class="label-txt">【转移学员学号】：{{ record.recordData.transferStudents }}</p>
                        <p class="label-txt">【接收学员学号】：{{ record.recordData.receiveStudents }}</p>
                        <p class="label-txt">【折扣情况】：{{ record.recordData.discountSituation }}</p>
                        <p class="label-txt">【课时转移记录】：{{ record.recordData.classTransferRecord }}</p>
                        <p class="label-txt">【课时转移原因】：{{ record.recordData.classTransferReason }}</p>
                        <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                        <p
                          class="label-txt"
                          v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                        >
                          【图片】：<img
                            style="width: 50px; height: 50px; margin-right: 5px"
                            preview="1"
                            v-for="(item, index) in record.recordData.imageList"
                            :src="item"
                            :key="index"
                          />
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDate">
                          【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                          【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.teachingFeedback">
                          【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                        </p>
                      </div>
                      <div
                        class="timeline-txt"
                        v-else-if="record.recordData.taskType === 'SUBSCRIPTION_DEDUCTION_FAILED_TASK'"
                      >
                        <p class="label-txt" v-if="record.recordData.nextCourse">
                          最近一节待开课时间{{ record.recordData.nextCourse }}
                        </p>
                        <p class="label-txt">
                          确认是否需取消对应的待开课课节：{{ record.recordData.needCancelCourse }}
                        </p>
                        <p class="label-txt">
                          与家长沟通扣款失败原因，引导更新支付方式确保扣款成功：{{
                            record.recordData.paymentCommunicationResult
                          }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.paymentFailReason">
                          失败原因：{{ record.recordData.paymentFailReason }}
                        </p>
                      </div>
                      <div
                        class="timeline-txt"
                        v-else-if="record.recordData.taskType === 'TASK_CLASS_ADMIN_FIRST_PASS'"
                      >
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">
                            请查看并核对花果学员档案、学情及跟进记录：{{ record.recordData.checkAcademicSituation }}
                          </p>
                          <p class="label-txt">
                            请确认排课系统中的待上课节数是否充足：{{ record.recordData.confirmCourseScheduling }}
                          </p>
                          <p class="label-txt">
                            与家长确认排课情况、确认上课时间及课频，班型及课时扣减规则、授课老师：{{
                              record.recordData.confirmClassTime
                            }}
                          </p>
                          <p class="label-txt">与家长确认是否存在遗留问题：{{ record.recordData.confirmIssues }}</p>
                          <p class="label-txt" v-if="record.recordData.confirmIssuesReason">
                            遗留问题处理记录：{{ record.recordData.confirmIssuesReason }}
                          </p>
                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【核对学情】：{{ record.recordData.checkLearningSituation }}</p>
                          <p class="label-txt">【身份介绍】：{{ record.recordData.identityIntroduction }}</p>
                          <p class="label-txt">【遗留问题】：{{ record.recordData.remainingProblems }}</p>
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="timeline-txt"
                        v-else-if="
                          record.recordData.taskType === 'TASK_STUDENT_ACTIVE' &&
                          record.recordData.version === 'version2'
                        "
                      >
                        <p class="label-txt">请记录回暖沟通内容：{{ record.recordData.communicatingActive }}</p>
                        <p class="label-txt">家长是否复课：{{ record.recordData.isResumeClasses }}</p>
                        <p class="label-txt" v-if="record.recordData.isResumeClasses === '是'">
                          复课时间：{{ record.recordData.resumeClassesTime }}
                        </p>
                        <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                        <p
                          class="label-txt"
                          v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                        >
                          图片：<img
                            style="width: 50px; height: 50px; margin-right: 5px"
                            preview="1"
                            v-for="(item, index) in record.recordData.imageList"
                            :src="item"
                            :key="index"
                          />
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDate">
                          下次跟进时间：{{ record.recordData.nextFollowDate }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                          下次跟进描述：{{ record.recordData.nextFollowDesc }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.teachingFeedback">
                          教学需求反馈：{{ record.recordData.teachingFeedback }}
                        </p>
                      </div>
                      <div
                        class="timeline-txt"
                        v-else-if="
                          record.recordData.taskType === 'TASK_MOTH_VISIT' ||
                          record.recordData.taskType === 'TASK_STUDENT_ACTIVE'
                        "
                      >
                        <p class="label-txt">【家长回暖】：{{ record.recordData.parentsRecovery }}</p>
                        <p class="label-txt">【沟通内容】：{{ record.recordData.policySynchronization }}</p>
                        <p class="label-txt">【复课计划】：{{ record.recordData.isResumeClasses }}</p>
                        <p
                          class="label-txt"
                          v-if="record.recordData.isResumeClasses === '是' && record.recordData.resumeClassesTime"
                        >
                          【复课时间】：{{ record.recordData.resumeClassesTime }}
                        </p>

                        <!-- <p class="label-txt">【阶段学情总结】：{{ record.recordData.stageLearningSummary }}</p>
                      <p class="label-txt">【家长反馈】：{{ record.recordData.parentalFeedback }}</p>
                      <p class="label-txt">【课后作业】：{{ record.recordData.homeworkAfterClass }}</p>
                      <p class="label-txt">【出勤次数】：{{ record.recordData.attendance }}</p>
                      <p class="label-txt" v-if="record.recordData.attendanceReason">
                        【缺席/迟到原因】：{{ record.recordData.attendanceReason }}
                      </p>
                      <p class="label-txt">【学习规划】：{{ record.recordData.learningPlanning }}</p> -->
                        <p class="label-txt" v-if="record.recordData.describe">
                          【跟进描述】：{{ record.recordData.describe }}
                        </p>
                        <p
                          class="label-txt"
                          v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                        >
                          【图片】：<img
                            style="width: 50px; height: 50px; margin-right: 5px"
                            preview="1"
                            v-for="(item, index) in record.recordData.imageList"
                            :src="item"
                            :key="index"
                          />
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDate">
                          【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                          【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.teachingFeedback">
                          【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                        </p>
                      </div>
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_W3_VISIT'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">
                            与家长沟通学员近期学习进步点，课堂表现、作业完成情况及待提升点：{{
                              record.recordData.communicatingStudent
                            }}
                          </p>
                          <p class="label-txt">
                            根据学员近期学习表现、薄弱点制定下一步学习规划及建议，铺垫学习重难点【{{
                              record.recordData.studentPerformance
                            }}】
                          </p>
                          <p class="label-txt">
                            补升方案介绍：结合学习情况铺垫方案性价比【{{ record.recordData.upgradePlan }}】
                          </p>
                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【学情分析】：{{ record.recordData.situationAnalysis }}</p>
                          <p class="label-txt">【补升方案介绍】：{{ record.recordData.promotionIntroduce }}</p>
                          <p class="label-txt">【学习规划制定】：{{ record.recordData.studyFormulate }}</p>
                          <p class="label-txt">【补升意向】：{{ record.recordData.promotionIntention }}</p>
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_FIRST_VISIT'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">授课老师：{{ record.recordData.instructor }}</p>
                          <p class="label-txt" v-if="record.subject === 'CHINESE'">
                            老师是否完成课前破冰：{{ record.recordData.icebreaker }}
                          </p>
                          <p class="label-txt" v-if="record.subject === 'CHINESE'">
                            老师是否在课后与家长完成沟通：{{ record.recordData.classCommunication }}
                          </p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.classCommunicationReason && record.subject === 'CHINESE'"
                          >
                            老师未完成课后沟通的原因：{{ record.recordData.classCommunicationReason }}
                          </p>
                          <p class="label-txt">首课级别：{{ record.recordData.firstClassLevel }}</p>
                          <p class="label-txt" v-if="record.recordData.feedback">
                            与家长反馈学员课堂参与度、开口占比及输出准确度、建议提升点等：{{
                              record.recordData.feedback
                            }}
                          </p>
                          <p class="label-txt">
                            了解家长对首课内容/学习进度的反馈：{{ record.recordData.understandingProgress }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.adjustLevel">
                            级别进度如何调整{{ record.recordData.adjustLevel }}
                          </p>
                          <p class="label-txt">
                            了解家长对首课老师的反馈：{{ record.recordData.understandingTeacher }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.parentFeedback">
                            其他反馈补充：{{ record.recordData.parentFeedback }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.parentExpectationManagement">
                            了解家长教学期望，与家长确认学习规划，做好预期管理：{{
                              record.recordData.parentExpectationManagement
                            }}
                          </p>
                          <p class="label-txt" v-if="record.subject === 'CHINESE'">
                            介绍如何复习、查作业和交作业：{{ record.recordData.reviewHomework }}
                          </p>
                          <p class="label-txt" v-if="record.subject === 'MATH'">
                            介绍如何复习、查作业和交作业、错题本功能：{{ record.recordData.reviewHomework }}
                          </p>
                          <p class="label-txt">
                            介绍分享规则、操作方法和上传路径：{{ record.recordData.sharingRules }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.communicationPreferences">
                            了解沟通时间及沟通方式偏好和学习负责人：{{ record.recordData.communicationPreferences }}
                          </p>

                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【授课老师】：{{ record.recordData.instructor }}</p>
                          <p class="label-txt">【看课反馈】：{{ record.recordData.watchClass }}</p>
                          <p class="label-txt">【首课感受】：{{ record.recordData.firstLessonFeel }}</p>
                          <p class="label-txt">【首课级别】：{{ record.recordData.firstClassLevel }}</p>
                          <p class="label-txt">【级别进度】：{{ record.recordData.levelProgress }}</p>
                          <p class="label-txt">【作业提醒】：{{ record.recordData.jobReminder }}</p>
                          <p class="label-txt" v-if="record.recordData.sendConfirmation">
                            【寄送确认】：{{ record.recordData.sendConfirmation }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teacherTask">
                            【老师任务】：{{ record.recordData.teacherTask }}
                          </p>
                          <p class="label-txt">【分享推荐】：{{ record.recordData.shareRecommendation }}</p>
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="timeline-txt"
                        v-else-if="['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(record.recordData.taskType)"
                      >
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt" v-if="record.recordData.selfBookingConfirm">
                            通知家长入学级别、班型、开课时间、请假规则、课时扣减规则等信息：
                            {{ record.recordData.selfBookingConfirm }}
                          </p>
                          <p class="label-txt">
                            沟通结果：{{
                              record.recordData.communicateResult === 'SUCCESS'
                                ? '沟通成功且已排课'
                                : '沟通失败OR未排课'
                            }}
                          </p>
                          <template v-if="record.recordData.communicateResult === 'SUCCESS'">
                            <p class="label-txt" v-if="record.recordData.studentInfo">
                              学员信息：中英文昵称、出生年月【{{ record.recordData.studentInfo }}】
                            </p>
                            <p class="label-txt" v-if="record.recordData.classIntention">
                              上课意向：首课时间、上课频次及可排课时间、老师风格【{{
                                record.recordData.classIntention
                              }}】
                            </p>
                            <p class="label-txt" v-if="record.recordData.admissionRights">
                              入学权益：课包、分享额度、课时扣减、课时有效期、退款手续费及规则【{{
                                record.recordData.admissionRights
                              }}】
                            </p>
                            <p class="label-txt" v-if="record.recordData.studyPlan">
                              与家长确认学习规划，做好预期管理，核对入学级别及班型、教学期望【{{
                                record.recordData.studyPlan
                              }}】
                            </p>
                            <p class="label-txt" v-if="record.recordData.classAccount">
                              与家长确认上课账号（海外手机号和邮箱），引导家长登陆Web/App【{{
                                record.recordData.classAccount
                              }}】
                            </p>
                            <p class="label-txt" v-if="record.recordData.learningLeader">
                              与家长确认学习负责人、沟通时间方式偏好、介绍APP24小时服务群和课前提醒【{{
                                record.recordData.learningLeader
                              }}】
                            </p>
                            <p class="label-txt" v-if="record.recordData.notifyParents">
                              通知家长排课信息和请假规则【{{ record.recordData.notifyParents }}】
                            </p>

                            <p class="label-txt" v-if="record.recordData.nicknameAndBirth">
                              【学员信息】中英文昵称、出生年月、常驻城市、学习负责人【{{
                                record.recordData.nicknameAndBirth
                              }}】
                            </p>
                            <p class="label-txt" v-if="record.recordData.courseInfo">
                              【课时信息】课包、分享额度/规则、课时有效期【{{ record.recordData.courseInfo }}】
                            </p>
                            <p class="label-txt" v-if="record.recordData.classArrangement">
                              【上课安排】核对入学级别，确认老师风格、上课频次及时间，课时扣减【{{
                                record.recordData.classArrangement
                              }}】
                            </p>
                            <p class="label-txt" v-if="record.recordData.appDownload">
                              与家长确认是否下载手机版WuKong
                              APP，与家长确认孩子的上课设备及上课账号，并引导登陆（海外手机号或邮箱）【{{
                                record.recordData.appDownload
                              }}】
                            </p>
                            <p class="label-txt" v-if="record.recordData.appIntroduction">
                              介绍App功能，课前预习、上课提醒、24小时客服【{{ record.recordData.appIntroduction }}】
                            </p>
                          </template>
                          <template v-if="record.recordData.communicateResult === 'FAILD'">
                            失败原因：{{ record.recordData.faildReason }}
                          </template>

                          <p class="label-txt" v-if="record.recordData.describe">
                            备注：{{ record.recordData.describe }}
                          </p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【身份职责】：{{ record.recordData.identity }}</p>
                          <p class="label-txt">【入学档案】：{{ record.recordData.enrollmentProfile }}</p>
                          <p class="label-txt" v-if="record.recordData.studentInformation">
                            【学员信息】：{{ record.recordData.studentInformation }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.attendClass">
                            【上课意向】：{{ record.recordData.attendClass }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.mailingAddress">
                            【邮寄地址】：{{ record.recordData.mailingAddress }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.newborn">
                            【入学权益】：{{ record.recordData.newborn }}
                          </p>
                          <p class="label-txt">【排课通知】：{{ record.recordData.scheduleNotice }}</p>
                          <p class="label-txt">【入学服务】：{{ record.recordData.admissionSituation }}</p>
                          <p class="label-txt">【服务信息计划】：{{ record.recordData.serviceInformation }}</p>
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_CHANGE_TEACHER_VISIT'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">授课老师：{{ record.recordData.instructor }}</p>
                          <p class="label-txt" v-if="record.subject === 'CHINESE'">
                            老师是否完成课前破冰：{{ record.recordData.icebreakerClass }}
                          </p>
                          <p class="label-txt" v-if="record.subject === 'CHINESE'">
                            老师是否在课后与家长完成沟通：{{ record.recordData.classCommunicationVisit }}
                          </p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.classCommunicationVisitReason && record.subject === 'CHINESE'"
                          >
                            老师未完成课后沟通的原因：{{ record.recordData.classCommunicationVisitReason }}
                          </p>

                          <p class="label-txt" v-if="record.recordData.feedbackVisit">
                            与家长反馈学员课堂参与度、开口占比及输出准确度、建议提升点等：{{
                              record.recordData.feedbackVisit
                            }}
                          </p>

                          <p class="label-txt">了解家长对首课老师的反馈：{{ record.recordData.underTeacherVisit }}</p>
                          <p class="label-txt" v-if="record.recordData.parentFeedbackVisit">
                            请填写家长对授课老师不满意的地方或其他情况补充：{{ record.recordData.parentFeedbackVisit }}
                          </p>

                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【授课老师】：{{ record.recordData.instructor }}</p>
                          <p class="label-txt">【看课反馈】：{{ record.recordData.watchClass }}</p>
                          <p class="label-txt">【首课感受】：{{ record.recordData.firstLessonFeel }}</p>
                          <p class="label-txt">【老师任务】：{{ record.recordData.teacherTask }}</p>
                          <p class="label-txt">【交接备注】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>

                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_LONG_TASK_OVER_VISIT'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">授课老师：{{ record.recordData.instructor }}</p>
                          <p class="label-txt" v-if="['MATH', 'ENGLISH'].includes(record.subject)">
                            与家长反馈学员课堂参与度、开口占比及输出准确度、建议提升点等：{{
                              record.recordData.feedbackOver
                            }}
                          </p>

                          <p class="label-txt" v-if="['MATH', 'ENGLISH'].includes(record.subject)">
                            了解家长对新老师的反馈：{{ record.recordData.underNewTeacher }}
                          </p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.parentFeedbackOver && ['MATH', 'ENGLISH'].includes(record.subject)"
                          >
                            请填写家长对授课老师不满意的地方或其他情况补充：{{ record.recordData.parentFeedbackOver }}
                          </p>

                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【看课反馈】：{{ record.recordData.watchClass }}</p>
                          <p class="label-txt">【首课感受】：{{ record.recordData.firstLessonFeel }}</p>

                          <p class="label-txt">【交接备注】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>

                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_STUDENT_ABSENT'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">了解学员缺勤原因：{{ record.recordData.absenteeismReasons }}</p>
                          <p class="label-txt" v-if="record.recordData.lessonArrangement">
                            请引导家长安排补课：{{ record.recordData.lessonArrangement }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.courseReplayAbsent">
                            提醒学员查看课程回放：{{ record.recordData.courseReplayAbsent }}
                          </p>
                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【旷课原因】：{{ record.recordData.absenteeismReasons }}</p>
                          <p class="label-txt" v-if="record.subject === 'CHINESE'">
                            【补课安排】：{{ record.recordData.lessonArrangement }}
                          </p>
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'STAGE_LEARNING_REPORT'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">
                            与家长沟通本阶段学生课堂表现（参与度、出勤、作业完成情况）、待提升点及建议：{{
                              record.recordData.communicatingReport
                            }}
                          </p>

                          <p class="label-txt">铺垫下阶段的学习内容和重难点：{{ record.recordData.studyReport }}</p>
                          <p class="label-txt">回访结果：{{ record.recordData.followupResultsReport }}</p>
                          <p class="label-txt" v-if="record.recordData.processingRecordsReport">
                            家长异议处理记录：{{ record.recordData.processingRecordsReport }}
                          </p>
                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【家长有无意见反馈】：{{ record.recordData.isParentFeedback }}</p>
                          <p class="label-txt" v-if="record.recordData.opinionFeedback">
                            【家长意见反馈】：{{ record.recordData.opinionFeedback }}
                          </p>
                          <p class="label-txt">【阶段学情总结】：{{ record.recordData.stageSummary }}</p>
                          <p class="label-txt">【互动作业提醒】：{{ record.recordData.operationReminder }}</p>
                          <p class="label-txt">【是否发送报告】：{{ record.recordData.isSendReport }}</p>
                          <!-- <p class="label-txt">【学习建议】：{{ record.recordData.learningSugReport }}</p> -->
                          <p class="label-txt">【课程预告】：{{ record.recordData.upcomingClassesReport }}</p>
                          <p class="label-txt">【分享推荐】：{{ record.recordData.shareRecommendationsReport }}</p>
                          <p class="label-txt">【是否邮寄教辅资料】：{{ record.recordData.isMailingInfo }}</p>
                          <!-- <p class="label-txt">【学习规划】：{{ record.recordData.learningPlanningReport }}</p> -->
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="timeline-txt"
                        v-else-if="record.recordData.taskType === 'TASK_RESIGN_STUDENT_OLD_CLASSADMIN'"
                      >
                        <div v-if="record.recordData.version === 'version2'">
                          <!-- <p class="label-txt">
                            请完成“学生主页服务页面”中的学生画像补充/修改：{{ record.recordData.studentPortrait }}
                          </p>
                          <p class="label-txt">
                            请完成“学生主页服务页面”中的家长画像补充/修改：{{ record.recordData.parentPortrait }}
                          </p>
                          <p class="label-txt">
                            请完成“学生主页学情页面”中当前学情补充/修改：{{ record.recordData.academicSituation }}
                          </p> -->
                          <p class="label-txt">分享参与度：{{ record.recordData.sharingEngagement }}</p>
                          <p class="label-txt" v-if="record.recordData.sharingEngagementReason">
                            请补充说明不参与分享的原因：{{ record.recordData.sharingEngagementReason }}
                          </p>
                          <p class="label-txt">
                            待解决异议点/问题：{{
                              record.recordData.solveProblems && record.recordData.solveProblems.length
                                ? record.recordData.solveProblems.join('、')
                                : record.recordData.solveProblem
                            }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.solveProblemReason">
                            请说明待解决异议点或遗留问题：{{ record.recordData.solveProblemReason }}
                          </p>
                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【学情交接】：{{ record.recordData.learningSituation }}</p>
                          <p class="label-txt">【课堂参与度】：{{ record.recordData.classroomParticipation }}</p>
                          <p class="label-txt">【出勤情况】：{{ record.recordData.attendance }}</p>
                          <p class="label-txt">【缺席/迟到原因】：{{ record.recordData.attendanceReason }}</p>
                          <p class="label-txt">【课后作业】：{{ record.recordData.homeworkAfterClass }}</p>
                          <p class="label-txt">【分享参与】：{{ record.recordData.shareParticipation }}</p>
                          <p class="label-txt">【不积极原因】：{{ record.recordData.shareReason }}</p>
                          <p class="label-txt">【教学期望】：{{ record.recordData.teachingService }}</p>
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="timeline-txt"
                        v-else-if="['TASK_CLASS_ADMIN_FIRST_PASS_V2', 'TASK_CLASS_ADMIN_FIRST_PASS_FOLLOW_UP_V2'].includes(record.recordData.taskType)"
                      >
                        <p class="label-txt">沟通结果：{{ record.recordData.communicationResult }}</p>
                        <p class="label-txt" v-if="record.recordData.failedReason">
                          失败原因：{{ record.recordData.failedReason }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDate">
                          下次跟进时间：{{ record.recordData.nextFollowDate }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.isCheckedStudent">
                          请查看并核对花果学员档案，学情及跟进记录：{{ record.recordData.isCheckedStudent }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.isCheckedSchedule">
                          请确认排课系统中待上课节是否充足：{{ record.recordData.isCheckedSchedule }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.isCheckedGenearch">
                          与家长确认排课情况、确认上课时间及课频，班型及课时扣减规则、授课老师：{{
                            record.recordData.isCheckedGenearch
                          }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.isCheckedLegacyIssues">
                          与家长确认是否存在遗留问题：{{ record.recordData.isCheckedLegacyIssues }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.legacyIssuesRecord">
                          遗留问题处理记录：{{ record.recordData.legacyIssuesRecord }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.remark">备注：{{ record.recordData.remark }}</p>
                        <p
                          class="label-txt"
                          v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                        >
                          图片：<img
                            style="width: 50px; height: 50px; margin-right: 5px"
                            preview="1"
                            v-for="(item, index) in record.recordData.imageList"
                            :src="item"
                            :key="index"
                          />
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDate">
                          下次跟进时间：{{ record.recordData.nextFollowDate }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                          下次跟进描述：{{ record.recordData.nextFollowDesc }}
                        </p>
                      </div>
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_TEACHER_ABSENT'">
                        <p class="label-txt">【老师旷课原因】：{{ record.recordData.absenteeism }}</p>
                        <p class="label-txt" v-if="record.recordData.absenteeismReason">
                          【其他原因】：{{ record.recordData.absenteeismReason }}
                        </p>
                        <p class="label-txt">【补课安排】：{{ record.recordData.makeUpLessons }}</p>
                        <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                        <p
                          class="label-txt"
                          v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                        >
                          【图片】：<img
                            style="width: 50px; height: 50px; margin-right: 5px"
                            preview="1"
                            v-for="(item, index) in record.recordData.imageList"
                            :src="item"
                            :key="index"
                          />
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDate">
                          【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                          【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.teachingFeedback">
                          【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                        </p>
                      </div>
                      <!--约课任务，线索创建任务-->
                      <div
                        class="timeline-txt"
                        v-else-if="
                          record.recordData.taskType === 'TASK_TRACK_CREATE' && record.recordData.version === 'version2'
                        "
                      >
                        <p class="label-txt">是否同意注册试听：{{ record.recordData.isAudition }}</p>
                        <p class="label-txt" v-if="record.recordData.isAudition === '未回复'">
                          是否至少跟进3天：{{ record.recordData.followDayClue }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.isAudition === '不同意'">
                          不注册试听原因：{{ record.recordData.isAuditionReason }}
                        </p>
                        <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                        <p
                          class="label-txt"
                          v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                        >
                          图片：<img
                            style="width: 50px; height: 50px; margin-right: 5px"
                            preview="1"
                            v-for="(item, index) in record.recordData.imageList"
                            :src="item"
                            :key="index"
                          />
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDate">
                          下次跟进时间：{{ record.recordData.nextFollowDate }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                          下次跟进描述：{{ record.recordData.nextFollowDesc }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.teachingFeedback">
                          教学需求反馈：{{ record.recordData.teachingFeedback }}
                        </p>
                      </div>

                      <!--拓科任务，约课-->
                      <div
                        class="timeline-txt"
                        v-else-if="
                          record.recordData.taskType === 'TASK_SUBSCRIBE_COURSE' &&
                          record.recordData.version === 'version2'
                        "
                      >
                        <p class="label-txt">是否同意注册试听：{{ record.recordData.isAuditionCourse }}</p>
                        <p class="label-txt" v-if="record.recordData.isAuditionCourse === '未回复'">
                          是否至少跟进3天：{{ record.recordData.followDayClue }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.isAuditionCourse === '同意'">
                          是否约课成功：{{ record.recordData.isAppointmentClass }}
                        </p>
                        <p
                          class="label-txt"
                          v-if="
                            record.recordData.isAuditionCourse === '同意' &&
                            record.recordData.isAppointmentClass === '未约'
                          "
                        >
                          未约课原因：{{ record.recordData.isAppointmentReason }}
                        </p>

                        <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                        <p
                          class="label-txt"
                          v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                        >
                          图片：<img
                            style="width: 50px; height: 50px; margin-right: 5px"
                            preview="1"
                            v-for="(item, index) in record.recordData.imageList"
                            :src="item"
                            :key="index"
                          />
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDate">
                          下次跟进时间：{{ record.recordData.nextFollowDate }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                          下次跟进描述：{{ record.recordData.nextFollowDesc }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.teachingFeedback">
                          教学需求反馈：{{ record.recordData.teachingFeedback }}
                        </p>
                      </div>
                      <div
                        class="timeline-txt"
                        v-else-if="
                          record.recordData.taskType === 'TASK_SUBSCRIBE_COURSE' ||
                          (record.recordData.taskType === 'TASK_TRACK_CREATE' && !record.recordData.version)
                        "
                      >
                        <p class="label-txt">【是否同意注册试听】：{{ record.recordData.isAudition }}</p>
                        <p class="label-txt" v-if="record.recordData.isAuditionResult">
                          【不注册试听原因】：{{ record.recordData.isAuditionResult }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.isAppointment">
                          【是否成功约课】：{{ record.recordData.isAppointment }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.appointmentResult">
                          【未约课原因】：{{ record.recordData.appointmentResult }}
                        </p>
                        <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                        <p
                          class="label-txt"
                          v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                        >
                          【图片】：<img
                            style="width: 50px; height: 50px; margin-right: 5px"
                            preview="1"
                            v-for="(item, index) in record.recordData.imageList"
                            :src="item"
                            :key="index"
                          />
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDate">
                          【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                          【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.teachingFeedback">
                          【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                        </p>
                      </div>
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_CERTIFICATE'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">
                            请与家长沟通学员课堂参与度、内容掌握情况、薄弱项及学习建议：{{
                              record.recordData.communicateCertificate
                            }}
                          </p>
                          <p class="label-txt">回访结果：{{ record.recordData.followResultsCertificate }}</p>
                          <p class="label-txt" v-if="record.recordData.recordsCertificate">
                            家长异议处理记录：{{ record.recordData.recordsCertificate }}
                          </p>
                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【家长有无意见反馈】：{{ record.recordData.feedback }}</p>
                          <p class="label-txt">【家长意见反馈】：{{ record.recordData.feedbackReason }}</p>
                          <p class="label-txt">【学情总结】：{{ record.recordData.learningSummary }}</p>
                          <!-- <p class="label-txt">【学习建议】：{{ record.recordData.learningSuggestions }}</p> -->
                          <p class="label-txt">【课程预告】：{{ record.recordData.upcomingClasses }}</p>
                          <p class="label-txt">【分享推荐】：{{ record.recordData.shareRecommendations }}</p>
                          <!-- <p class="label-txt">【学习规划】：{{ record.recordData.learningPlanning }}</p> -->
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_NOT_RENEW'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">是否抽检：{{ record.recordData.isExamination }}</p>

                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt" v-if="record.recordData.isRenewalReason">
                            【不续费原因】：{{ record.recordData.isRenewalReason }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.isRenewalReasonSub">
                            【不续费次要原因】：{{ record.recordData.isRenewalReasonSub }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.cancelCourse">
                            【是否取消所有课程】：{{ record.recordData.cancelCourse }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.lossDirection">
                            【流失去向】：{{ record.recordData.lossDirection }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.classAdminRecords">
                            【班主任沟通概述】：{{ record.recordData.classAdminRecords }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.isExamination">
                            【是否抽查】：{{ record.recordData.isExamination }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.isResponsible">
                            【班主任是否有责】：{{ record.recordData.isResponsible }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.responsibleReason">
                            【班主任有责原因】：{{ record.recordData.responsibleReason }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.threeResponsible">
                            【三级原因是否有误】：{{ record.recordData.threeResponsible }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.inspectionRemarks">
                            【抽查备注】：{{ record.recordData.inspectionRemarks }}
                          </p>
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>

                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <!--新生补升-->
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_PROMOTE'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">补升情况：{{ record.recordData.isMakeUp }}</p>
                          <p class="label-txt" v-if="record.recordData.oneMakeUpReason">
                            未补升一级原因：{{ record.recordData.oneMakeUpReason }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.twoMakeUpReason">
                            未补升二级原因：{{ record.recordData.twoMakeUpReason }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.threeMakeUpReason">
                            未补升三级原因：{{ record.recordData.threeMakeUpReason }}
                          </p>
                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【是否补升】：{{ record.recordData.isMakeUp }}</p>
                          <p class="label-txt" v-if="record.recordData.noMakeUpReason">
                            【未补升原因I】：{{ record.recordData.noMakeUpReason }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.noMakeUpReason2">
                            【未补升原因II】：{{ record.recordData.noMakeUpReason2 }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.noMakeUpReason3">
                            【未补升原因III】：{{ record.recordData.noMakeUpReason3 }}
                          </p>

                          <p class="label-txt" v-if="record.recordData.parentObjection">
                            【家长异议】：{{ record.recordData.parentObjection }}
                          </p>
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <!--学习回访-->
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_MOTH_STUDY_VISIT'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">
                            与家长沟通学员近期课堂表现、出勤及作业完成情况、待提升点及建议等：{{
                              record.recordData.communicatingMothStudy
                            }}
                          </p>
                          <p class="label-txt">回访结果：{{ record.recordData.followUpResults }}</p>
                          <p class="label-txt" v-if="record.recordData.processingRecords">
                            家长异议处理记录：{{ record.recordData.processingRecords }}
                          </p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【跟进结果】：{{ record.recordData.followResult }}</p>
                          <p class="label-txt">【学情反馈】：{{ record.recordData.studyFeedBack }}</p>
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <!--老师迟到任务-->
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TEACHER_LATE'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">告知家长课时已自动返还：{{ record.recordData.reverseClassHours }}</p>

                          <p class="label-txt" v-if="record.recordData.makeUpClasses">
                            请引导家长安排补课：{{ record.recordData.makeUpClasses }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.viewPlayback">
                            提醒学员查看课程回放：{{ record.recordData.viewPlayback }}
                          </p>
                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <!--阶段回访任务-->
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_TERM_VISIT'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">
                            与家长沟通本阶段学生课堂表现（参与度、出勤、作业完成情况）、待提升点及建议：{{
                              record.recordData.communicatingTerm
                            }}
                          </p>

                          <p class="label-txt">铺垫下阶段的学习内容和重难点：{{ record.recordData.studyTerm }}</p>
                          <p class="label-txt">回访结果：{{ record.recordData.followupResultsTerm }}</p>
                          <p class="label-txt" v-if="record.recordData.processingRecordsTerm">
                            家长异议处理记录：{{ record.recordData.processingRecordsTerm }}
                          </p>
                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【家长有无意见反馈】：{{ record.recordData.feedbackTerm }}</p>
                          <p class="label-txt" v-if="record.recordData.termFeedback">
                            【家长意见反馈】：{{ record.recordData.termFeedback }}
                          </p>
                          <p class="label-txt">【阶段学情总结】：{{ record.recordData.learningTermSummary }}</p>
                          <p class="label-txt">【互动作业提醒】：{{ record.recordData.operationTermReminder }}</p>
                          <!-- <p class="label-txt">【学习建议】：{{ record.recordData.learningTermSuggestions }}</p> -->
                          <p class="label-txt">【课程预告】：{{ record.recordData.upcomingTermClasses }}</p>
                          <p class="label-txt">【分享推荐】：{{ record.recordData.shareTermRecommendations }}</p>
                          <!-- <p class="label-txt">【学习规划】：{{ record.recordData.learningTermPlanning }}</p> -->
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="timeline-txt"
                        v-else-if="['TASK_WEEK_VISIT', 'REVIEW_QUESTION_REMINDER'].includes(record.recordData.taskType)"
                      >
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">
                            与家长沟通学员近期课堂表现、出勤及作业完成情况、待提升点及建议等：{{
                              record.recordData.communicatingWeek
                            }}
                          </p>
                          <p class="label-txt">回访结果：{{ record.recordData.followupResultsWeek }}</p>
                          <p class="label-txt" v-if="record.recordData.processingRecordsWeek">
                            家长异议处理记录：{{ record.recordData.processingRecordsWeek }}
                          </p>

                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【跟进结果】：{{ record.recordData.followResult }}</p>
                          <p class="label-txt">【学习建议】：{{ record.recordData.weekSuggestions }}</p>
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>

                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <!--流失学员首通-->
                      <div
                        class="timeline-txt"
                        v-else-if="record.recordData.taskType === 'TASK_LOSS_REFUND_STUDENT_FIRST_PASS'"
                      >
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt" v-if="record.recordData.oneLossReason">
                            流失一级原因：{{ record.recordData.oneLossReason }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.twoLossReason">
                            流失二级原因：{{ record.recordData.twoLossReason }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.threeLossReason">
                            流失三级原因：{{ record.recordData.threeLossReason }}
                          </p>
                          <p class="label-txt">家长在意点：{{ record.recordData.parentsCare }}</p>
                          <p class="label-txt">学员复课意向：{{ record.recordData.resumeClassesLoss }}</p>
                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【流失原因】：{{ record.recordData.loseReason }}</p>
                          <p class="label-txt">【家长在意点】：{{ record.recordData.parentsCare }}</p>
                          <p class="label-txt">【学员复课意向】：{{ record.recordData.resumeClasses }}</p>
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <!--时令变化-->

                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'DAYLIGHT_TIME_CHANGED'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">
                            通知更换时令后的上课时间并得到家长确认：{{ record.recordData.seasonalChanges }}
                          </p>

                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【跟进结果】：{{ record.recordData.followResult }}</p>
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <!--学员跟进-->

                      <div
                        class="timeline-txt"
                        v-else-if="
                          record.recordData.taskType === 'TASK_NEXT_FOLLOW_RECORD' &&
                          record.recordData.version === 'version2'
                        "
                      >
                        <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                        <p
                          class="label-txt"
                          v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                        >
                          图片：<img
                            style="width: 50px; height: 50px; margin-right: 5px"
                            preview="1"
                            v-for="(item, index) in record.recordData.imageList"
                            :src="item"
                            :key="index"
                          />
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDate">
                          下次跟进时间：{{ record.recordData.nextFollowDate }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                          下次跟进描述：{{ record.recordData.nextFollowDesc }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.teachingFeedback">
                          教学需求反馈：{{ record.recordData.teachingFeedback }}
                        </p>
                      </div>

                      <!--一家多娃任务-->
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'TASK_STUDENT_MULTI_CHILD'">
                        <div v-if="record.recordData.version === 'version2'">
                          <p class="label-txt">是否存在多娃：{{ record.recordData.existChild }}</p>
                          <p class="label-txt" v-if="record.recordData.existChild === '未回复'">
                            是否至少跟进3天：{{ record.recordData.followDay }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.existChild === '是'">
                            是否同意注册：{{ record.recordData.agreeRegister }}
                          </p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.agreeRegister === '同意' && record.recordData.existChild === '是'"
                          >
                            同意注册科目：{{ record.recordData.registerSubject }}
                          </p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.agreeRegister === '不同意' && record.recordData.existChild === '是'"
                          >
                            中文课不注册原因：{{ record.recordData.chineseReason }}
                          </p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.agreeRegister === '不同意' && record.recordData.existChild === '是'"
                          >
                            数学课不注册原因：{{ record.recordData.mathReason }}
                          </p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.agreeRegister === '不同意' && record.recordData.existChild === '是'"
                          >
                            英文课不注册原因：{{ record.recordData.englishReason }}
                          </p>
                          <p class="label-txt">备注：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            图片：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                        <div v-else>
                          <p class="label-txt">【是否存在多娃】：{{ record.recordData.isChild }}</p>
                          <p class="label-txt">【多娃拓科科目选择】：{{ record.recordData.isChildSubject }}</p>
                          <p class="label-txt">【是否同意注册试听】：{{ record.recordData.isAgreeAudition }}</p>
                          <p class="label-txt">【不注册试听原因】：{{ record.recordData.isAgreeReason }}</p>
                          <p class="label-txt">【跟进描述】：{{ record.recordData.describe }}</p>
                          <p
                            class="label-txt"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            【图片】：<img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </p>
                          <p class="label-txt" v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </p>
                        </div>
                      </div>
                      <!-- 离线任务 -->
                      <div class="timeline-txt" v-else-if="record.recordData.taskType === 'OFFLINE_UPLOAD'">
                        <p class="label-txt" v-if="record.recordData.offlineUploadCommStatus">
                          沟通情况：{{ record.recordData.offlineUploadCommStatus }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.offlineUploadCommTime">
                          沟通时间：{{ record.recordData.offlineUploadCommTime }}
                        </p>
                        <p class="label-txt" v-if="record.recordData.offlineUploadNotCommReason">
                          未沟通原因：{{ record.recordData.offlineUploadNotCommReason }}
                        </p>
                      </div>
                      <div v-else>
                        <div class="timeline-txt" v-if="record.recordData.version === 'version2'">
                          <div v-if="record.recordData.method === '邮件'">
                            邮件主题：{{ record.recordData.describe
                            }}<span
                              style="cursor: pointer; padding-left: 20px; color: #04cb94"
                              @click="lookDetailEmail(record)"
                              >查看详情</span
                            >
                          </div>
                          <div v-else>备注：{{ record.recordData.describe }}</div>
                          <div v-if="record.recordData.nextFollowDate">
                            下次跟进时间：{{ record.recordData.nextFollowDate }}
                          </div>
                          <div v-if="record.recordData.nextFollowDesc">
                            下次跟进描述：{{ record.recordData.nextFollowDesc }}
                          </div>
                          <div v-if="record.recordData.teachingFeedback">
                            教学需求反馈：{{ record.recordData.teachingFeedback }}
                          </div>
                          <div
                            style="margin-top: 5px"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            <img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </div>
                        </div>
                        <div class="timeline-txt" v-else>
                          <div v-if="record.recordData.method === '邮件'">
                            【邮件主题】：{{ record.recordData.describe
                            }}<span
                              style="cursor: pointer; padding-left: 20px; color: #04cb94"
                              @click="lookDetailEmail(record)"
                              >查看详情</span
                            >
                          </div>
                          <div v-else-if="record.recordData.describe">
                            【跟进描述】：{{ record.recordData.describe }}
                          </div>
                          <div v-if="record.recordData.nextFollowDate">
                            【下次跟进时间】：{{ record.recordData.nextFollowDate }}
                          </div>
                          <div v-if="record.recordData.nextFollowDesc">
                            【下次跟进描述】：{{ record.recordData.nextFollowDesc }}
                          </div>
                          <div v-if="record.recordData.teachingFeedback">
                            【教学需求反馈】：{{ record.recordData.teachingFeedback }}
                          </div>
                          <div
                            style="margin-top: 5px"
                            v-if="record.recordData.imageList && record.recordData.imageList.length > 0"
                          >
                            <img
                              style="width: 50px; height: 50px; margin-right: 5px"
                              preview="1"
                              v-for="(item, index) in record.recordData.imageList"
                              :src="item"
                              :key="index"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        style="margin: 5px 0 0 35px"
                        v-if="record.recordData.zoomRecord && record.recordData.zoomRecord.url"
                      >
                        <audio
                          preload="metadata"
                          controls
                          controlsList="nodownload"
                          :src="`${baseUrl}/zoom/phone/record/play?url=${record.recordData.zoomRecord.url}`"
                        />
                      </div>
                      <div class="tag-wrap">
                        <a-tag color="orange" v-if="record.recordData.taskType">
                          {{ initOptionsType1(record.recordData.taskType, followTypeArr) }}
                        </a-tag>
                        <span class="tag-txt">科目：{{ initOptionsType(record.subject, subjectArr) }}</span>
                        <span class="tag-txt" v-if="record.recordData.method"
                          >跟进方式：{{ record.recordData.method }}</span
                        >
                        <!-- <span class="tag-txt" v-if="record.recordData.phoneResult"
                        >电话结果：{{ record.recordData.phoneResult }}</span
                      > -->
                        <span
                          class="tag-label"
                          style="color: #43d186; display: inline-flex; align-items: center"
                          v-if="record.taskId"
                          ><img src="@/assets/icon_finish.png" class="icon_finish" /> 已完成任务</span
                        >
                      </div>
                    </div>
                    <div v-if="record.recordData !== null && record.recordData.zoomRecord">
                      <div class="record-btnWrap">
                        <span class="recordBtn" @click="openRecordsModal(record)">
                          <a-icon type="plus" />跟进记录
                        </span>
                        <span class="record-txt">超过30秒建议添加跟进记录</span>
                      </div>
                      <!-- <div>
                                  <div class="timeline-infoWrap">
                                    <div class="avator-pic">
                                      <img :src="record.headImageUrl" />
                                    </div>
                                    <div class="timeline-name">{{ record.operatorName }}</div>
                                    <div class="reply-time">{{ record.whenHour }}</div>
                                  </div>
                                  <div class="timeline-txt">{{ record.recordData.describe }}</div>
                                </div> -->
                      <div
                        style="margin: 5px 0 0 35px"
                        v-if="record.recordData.zoomRecord && record.recordData.zoomRecord.url"
                      >
                        <audio
                          preload="metadata"
                          controls
                          controlsList="nodownload"
                          :src="`${baseUrl}/zoom/phone/record/play?url=${record.recordData.zoomRecord.url}`"
                        />
                      </div>
                      <!-- <div class="tag-wrap">
                                  <a-tag color="orange">
                                    {{ record.taskName }}
                                  </a-tag>
                                  <span class="tag-txt">方式：{{ record.recordData.method }}</span>
                                  <span class="tag-txt" v-if="record.recordData.phoneResult"
                                    >电话结果：{{ record.recordData.phoneResult }}</span
                                  >
                                </div> -->
                    </div>
                  </div>
                </div>
              </a-timeline-item>
            </a-timeline>

            <div class="empty-wrap" v-else>
              <div class="empty-pic">
                <img src="@/assets/newImage/empty.png" />
              </div>
              <div class="empty-txt">暂无数据</div>
            </div>
          </div>
        </a-spin>
      </a-tab-pane>
      <a-tab-pane key="CONSULTANT" tab="学习规划师">
        <div>
          <a-radio-group default-value="CHINESE" @change="changeSubject">
            <a-radio-button v-for="item in subjectArr" :key="item.value" :value="item.value">{{
              item.label
            }}</a-radio-button>
          </a-radio-group>
        </div>
        <a-spin :spinning="spinning">
          <div class="timeline-wrap">
            <a-timeline v-if="recordData.length > 0">
              <a-timeline-item v-for="(item, index) in recordData" :key="index">
                <div class="timeline-date">
                  {{ item.key }}
                </div>
                <div class="timeline-content">
                  <div class="timeline-item" v-for="(record, index1) in item.value" :key="index1">
                    <div v-if="record.recordData">
                      <div class="timeline-infoWrap">
                        <div class="avator-pic">
                          <img src="@/assets/consultant.png" />
                        </div>
                        <div class="timeline-name">{{ record.operatorName }}</div>
                        <div class="reply-time">{{ record.whenHour }}</div>
                      </div>
                      <div
                        class="describeUrl"
                        v-if="record.recordData.describeUrls && record.recordData.describeUrls.length > 0"
                      >
                        <img
                          v-for="(src, index) in record.recordData.describeUrls"
                          :src="src"
                          :key="index"
                          preview="1"
                        />
                      </div>
                      <div class="timeline-txt">
                        【邮件主题】：{{ record.recordData.describe
                        }}<span
                          v-if="record.recordData.method === '邮件'"
                          style="cursor: pointer; padding-left: 20px; color: #04cb94"
                          @click="lookDetailEmail(record)"
                          >查看详情</span
                        >
                      </div>
                      <div
                        style="margin: 5px 0 0 35px"
                        v-if="record.recordData.zoomRecord && record.recordData.zoomRecord.url"
                      >
                        <audio
                          preload="metadata"
                          controls
                          controlsList="nodownload"
                          :src="`${baseUrl}/zoom/phone/record/play?url=${record.recordData.zoomRecord.url}`"
                        />
                      </div>
                      <div class="tag-wrap">
                        <a-tag color="orange" v-if="record.recordData.taskType" style="margin-right: 20px">
                          {{ initOptionsType1(record.recordData.taskType, followTypeArr) }}
                        </a-tag>
                        <span class="tag-txt">科目：{{ initOptionsType(record.subject, subjectArr) }}</span>
                        <span class="tag-txt" v-if="record.recordData.method"
                          >跟进方式：{{ record.recordData.method }}</span
                        >
                        <!-- <span class="tag-txt" v-if="record.recordData.phoneResult"
                        >电话结果：{{ record.recordData.phoneResult }}</span
                      > -->
                        <span
                          class="tag-label"
                          style="color: #43d186; display: inline-flex; align-items: center"
                          v-if="record.taskId"
                          ><img src="@/assets/icon_finish.png" class="icon_finish" /> 已完成任务</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </a-timeline-item>
            </a-timeline>
            <div class="empty-wrap" v-else>
              <div class="empty-pic">
                <img src="@/assets/newImage/empty.png" />
              </div>
              <div class="empty-txt">暂无数据</div>
            </div>
          </div>
        </a-spin>
      </a-tab-pane>
      <a-tab-pane key="TEACHER" tab="老师">
        <div>
          <a-radio-group default-value="CHINESE" @change="changeSubject">
            <a-radio-button v-for="item in subjectTeacherArr" :key="item.value" :value="item.value">{{
              item.label
            }}</a-radio-button>
          </a-radio-group>
        </div>
        <a-spin :spinning="spinning">
          <div class="timeline-wrap">
            <a-timeline v-if="recordData.length > 0">
              <a-timeline-item v-for="(item, index) in recordData" :key="index">
                <div class="timeline-date">
                  {{ item.key }}
                </div>
                <div class="timeline-content">
                  <div class="timeline-item" v-for="(record, index1) in item.value" :key="index1">
                    <div v-if="record.recordData">
                      <div class="timeline-infoWrap">
                        <div class="avator-pic">
                          <img src="@/assets/consultant.png" />
                        </div>
                        <div class="timeline-name">{{ record.operatorName }}</div>
                        <div class="reply-time">{{ record.whenHour }}</div>
                      </div>

                      <div class="timeline-txt">
                        <p class="label-txt">【相关课节】：{{ record.recordData.relateSchedule }}</p>
                        <p class="label-txt">【教学反馈】：{{ record.recordData.teachingFeedBack }}</p>
                      </div>

                      <div class="tag-wrap">
                        <a-tag color="orange" v-if="record.recordData.taskType" style="margin-right: 20px">
                          {{ initOptionsType1(record.recordData.taskType, followTypeArr) }}
                        </a-tag>
                        <span class="tag-txt">科目：{{ initOptionsType(record.subject, subjectArr) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a-timeline-item>
            </a-timeline>
            <div class="empty-wrap" v-else>
              <div class="empty-pic">
                <img src="@/assets/newImage/empty.png" />
              </div>
              <div class="empty-txt">暂无数据</div>
            </div>
          </div>
        </a-spin>
      </a-tab-pane>
    </a-tabs>
    <record-modal
      @refleshRecord="refleshRecord"
      :taskObj="taskObj"
      :visible="visible"
      v-if="visible"
      :type="recordType"
      @closeModal="closeRecordModal"
    ></record-modal>
    <emailDetail
      v-if="showEmailDetail"
      @onCancel="cancelEmailDetail"
      :recordData="recordEmailData"
      :roleType="roleType"
    ></emailDetail>
  </div>
</template>
<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { getFollowRecord, queryFormType } from '@/api/headTeacher';
import recordModal from '@/views/mainHome/recordModal.vue';
import emailDetail from '@/views/mainHome/emailDetail.vue';

const session = storage.createStore(sessionStorage);

export default {
  name: 'studentFollow',
  data() {
    return {
      showSubject: 'CHINESE',
      spinning: true,
      recordEmailData: {},
      showEmailDetail: false,
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
        { label: '其他', value: 'OTHER' },
      ],
      subjectTeacherArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      classSubject: 'CHINESE',
      conSubject: 'CHINESE',
      subject: 'CHINESE',
      followTypeArr: [],
      visible: false,
      taskObj: {},
      recordType: 'COMMON',
      roleType: 'CLASS_ADMIN',
      recordData: [],
      followTaskType: '',
      studentProfile: {},
      baseUrl: process.env.VUE_APP_API_IM_URL,
    };
  },
  components: {
    recordModal,
    emailDetail,
  },
  methods: {
    cancelEmailDetail() {
      this.showEmailDetail = false;
    },
    lookDetailEmail(record) {
      console.log(record);
      this.showEmailDetail = true;
      this.recordEmailData = record;
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });

      return name;
    },
    initOptionsType1(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.type === value) {
          name = item.name;
        }
      });

      return name;
    },
    changeSubject(e) {
      this.spinning = true;
      if (this.roleType === 'CLASS_ADMIN') {
        this.classSubject = e.target.value;
        this.subject = this.classSubject;
      } else {
        this.conSubject = e.target.value;
        this.subject = this.conSubject;
      }

      // 获取跟进记录类型、
      this.queryFormType();
      this.getFollowRecord();
    },
    handleChangeType(value) {
      this.followTaskType = value;
      this.getFollowRecord();
    },
    queryFormType() {
      const params = {
        subject: this.subject === 'OTHER' ? '' : this.subject,
      };
      queryFormType(params).then((res) => {
        this.followTypeArr = res.data.templateTypes;
      });
    },
    refleshRecord(subject) {
      this.subject = subject;
      this.showSubject = subject;
      this.getFollowRecord();
    },
    closeRecordModal() {
      this.visible = false;
    },
    openRecordsModal(item) {
      this.recordType = 'COMMON';
      this.taskObj = {
        studentId: this.studentProfile.uuid,
        followType: 'COMMON',
        uuid: item.uuid,
        studentName: this.studentProfile.fullName,
        firstCourseDate: this.firstLesson,
        taskId: item.taskId ? item.taskId : '',
        taskName: item.taskName ? item.taskName : '',
        processInstanceId: item.processInstanceId ? item.processInstanceId : '',
      };
      this.visible = true;
    },
    // 获取跟进记录详情
    getFollowRecord() {
      const params = {
        studentCode: this.studentProfile.code,
        type: this.roleType,
        subject: this.subject,
        studentId: this.studentProfile.uuid,
      };
      if (this.roleType === 'CLASS_ADMIN') {
        // 这块要改造传taskType
        params.taskType = this.followTaskType;
      }
      getFollowRecord(params).then((res) => {
        this.recordData = res.data;
        this.spinning = false;
      });
    },
    changeRole(value) {
      this.spinning = true;
      this.recordData = [];
      this.roleType = value;
      if (this.roleType === 'CLASS_ADMIN') {
        this.subject = this.classSubject;
      } else {
        this.subject = this.conSubject;
      }
      this.followTaskType = '';
      this.getFollowRecord();
    },
  },
  mounted() {
    this.studentProfile = session.get('studentProfile');
    this.getFollowRecord();
    // 获取跟进记录类型、
    this.queryFormType();
  },
};
</script>

<style lang="less" scoped>
.timeline-wrap {
  padding: 20px 10px;
  height: 60vh;
  overflow-y: scroll;
  position: relative;
}
.timeline-content {
  width: 100%;

  border: 1px solid #ebedf0;
  border-radius: 12px;
  padding: 0 10px;
  margin-top: 14px;
}
.record-btnWrap {
  margin-top: 14px;
  padding-left: 40px;
}
.tag-wrap {
  margin-top: 14px;
  padding-left: 40px;
}
.timeline-infoWrap {
  display: flex;
  align-items: center;
}
.avator-pic {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
  }
}
.timeline-txt {
  padding-left: 40px;
}
.reply-time {
  flex: 1;
  text-align: right;
}
.timeline-item {
  border-bottom: 1px solid #ebedf0;
  padding: 10px 0;
  &:last-child {
    border-bottom: none;
  }
}
.tag-txt {
  padding-right: 20px;
  color: #a5b5c1;
  font-size: 12px;
}

.follow-wrap {
  position: relative;
}
.top-recordBtn {
  position: absolute;
  right: 10px;
  top: 81px;
  z-index: 100;
}
.recordBtn {
  padding: 2px 10px;
  border: 1px solid #43d186;
  border-radius: 2px;
  color: #43d186;
  font-size: 14px;
  cursor: pointer;
}
.tag-label {
  background: rgba(67, 209, 134, 0.06);
  border-radius: 2px;
  padding: 3px 5px;
  margin-left: 20px;
}
.icon_finish {
  width: 14px;
  height: 14px;
  margin-right: 3px;
}
.empty-wrap {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 120px;
}
.empty-pic {
  width: 140px;
  height: 140px;
  img {
    width: 100%;
    height: 100%;
  }
}
.empty-txt {
  color: #c0ccda;
  text-align: center;
}
.sel-nav {
  display: flex;
}
</style>

import { render, staticRenderFns } from "./studentService.vue?vue&type=template&id=25b1a638&scoped=true&"
import script from "./studentService.vue?vue&type=script&lang=js&"
export * from "./studentService.vue?vue&type=script&lang=js&"
import style0 from "./studentService.vue?vue&type=style&index=0&id=25b1a638&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25b1a638",
  null
  
)

export default component.exports